const WLS = window.location.search;
const WLH = window.location.hash;
const WLP = window.location.pathname;

/**
 * Extract base64 param from:
 * - hash
 * - search
 * - pathname
 *
 * @returns {string}
 */
const extractData = () =>
    (WLS.trim() || WLH.trim() || WLP.trim()).replace(/[#\/?]/g, "");

/**
 * Decode data rom base64
 *
 * @returns {any}
 * @constructor
 */
export default () => {

    let urlData = {};

    try {

        let encodedData = extractData();
        const decodedData = atob(encodedData);
        urlData = JSON.parse(decodedData);
        urlData.apiUrl = urlData.apiUrl.replace(/\${taskId}/g, urlData["taskId"]);
    } catch (e) {
        console.error("Invalid task");
    }


    return urlData;

};
