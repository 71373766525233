function base64UrlDecode ( str ) {
    let base64 = str.replace( /-/g, '+' ).replace( /_/g, '/' );
    switch (base64.length % 4) {
        case 2:
            base64 += '==';
            break;
        case 3:
            base64 += '=';
            break;
    }
    return atob( base64 );
}

function parseJwt ( token ) {
    const parts = token.split( '.' );
    if ( parts.length !== 3 ) {
        throw new Error( 'Invalid JWT token' );
    }

    const header = JSON.parse( base64UrlDecode( parts[ 0 ] ) );
    const payload = JSON.parse( base64UrlDecode( parts[ 1 ] ) );
    const signature = parts[ 2 ];

    return { header, payload, signature };
}

function isTokenExpired(payload, marginInSeconds = 300) {
    if (!payload.exp) {
        return false;
    }

    const currentTime = Math.floor(Date.now() / 1000);
    return payload.exp < (currentTime + marginInSeconds);
}

export {
    parseJwt,
    isTokenExpired
}