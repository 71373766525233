/* jshint esversion: 11 */

import LazyLoad from "../../lib/LazyLoad";


import {
    cleanup,
    elem,
    localforage,
    appSelector,
    taskId,
} from "../../config";

/* A small workaround due to the non-standard coordinate name in the Simone component */
const prepareCoordinates = (model) => {

    let coordinates = {};

    if (model.coordinates && model.coordinates.latitude && model.coordinates.longitude) {

        coordinates = { lat: Number(model.coordinates.latitude), lng: Number(model.coordinates.longitude) };

    } else {
        const split = model.coordinates.split(",");

        coordinates = {
            lat: Number(split[0].trim()),
            lng: Number(split[1].trim()),
        };
    }

    return coordinates;

}

const prepareStyles = () => {
    let div = document.createElement("div");
    div.setAttribute("id", "ap");
    elem.appendChild(div);

    let elemStyle = document.createElement("style");
    elemStyle.innerHTML = "*, ::after, ::before {\n	box-sizing: revert;\n}";
    elem.appendChild(elemStyle);
}

const PLUGIN_JS = "https://dat-france-partner.vercel.app/assets/index.91ffb718.js";

/**
 * Based on WeDAT Plugin to assign partner o the claim.
 *
 * @returns {Promise<unknown>}
 * @constructor
 */
export default async function AssignPartner(configuration) {

    window.scrollTo(0, 0);

    elem.innerHTML = "";

    prepareStyles();

    return new Promise((resolve, reject) => {

        localforage.getItem(taskId).then((result) => {

            /* This is model from ROOT node */
            const { model } = result;

            /* This is component model */
            const { model: { maxdistance }, fieldName } = configuration;

            let newResult = result;

            const PluginProps = {
                selector: `#ap`,
                maxDistance: maxdistance || 50,
                contractId: model?.claimId,
                activePartnerId: model?.[fieldName],

                address: `${model.street}, ${model.zip} ${model.city}`,

                onPartnerAssigned: (selectedPartner) => {

                    if (selectedPartner) {

                        if (newResult && newResult.model) {
                            newResult.model[fieldName] = selectedPartner;
                        } else {
                            newResult = {
                                model:
                                    { partnerId: selectedPartner }
                            };
                        }
                        localforage.setItem(taskId, newResult).then((res) => {
                            cleanup(); /* This is workaround due to bug in widget */

                            resolve();
                        });
                    }
                }
            };


            PluginProps.claimPosition = prepareCoordinates(model);

            window.DAT_PARTNERS.init(PluginProps);
        });
    });
}
